<template>
  <router-view />
  <!-- <div v-else>
    <login-page></login-page>
  </div> -->
</template>
<script>
  // import AppHeader from "./components/AppHeader.vue";
  // import LoginPage from "./views/LoginPage.vue";
  import moment from "moment";

  export default {
    data() {
      return {
        user_session: localStorage.getItem("GDSC_token"),
      };
    },
    components: {
      // AppHeader,
      // LoginPage,
    },
    methods: {
      closeAddModal() {
        this.isShowAddModal = false;
      },
      showAddModal() {
        this.isShowAddModal = true;
      },
      closeEditModal() {
        this.isShowEditModal = false;
      },
      showEditModal() {
        this.isShowEditModal = true;
      },
      dateTime(value) {
        return moment(value).format("YYYY-MM-DD");
      },
      // async checkCookie() {
      //   if (localStorage.getItem("GDSC_token") === null) {
      //     this.responseAlert(" الرجاء تسجيل الدخول ", " عفوا ", "warning");
      //     this.$router.push("/LoginPage");
      //   }
      // },
      // all response alert
      responseAlert(text, title, icon) {
        this.$swal.fire({
          title: `${title}`,
          text: ` ${text} `,
          icon: `${icon}`,
          toast: true,
          position: "top-start",
          showConfirmButton: false,
          timer: 2000,
        });
      },
    },
    provide() {
      return {
        checkCookie: this.checkCookie,
        responseAlert: this.responseAlert,
        dateTime: this.dateTime,
      };
    },
    async mounted() {
      // await this.checkCookie();
      if (localStorage["hala_user"]) {
        this.$store.commit("setUser", JSON.parse(localStorage["hala_user"]));
      }
    },
  };
</script>

<style>
  a {
    cursor: pointer !important;
  }
  .img-fluid {
    width: 100%;
    height: auto;
  }
  button.text-gray-400.bg-transparent {
    margin-inline: 0 !important;
  }
  .image-preview {
    max-width: 150px;
    margin-top: 1rem;
    overflow: hidden;
    border: 2px solid #128880;
  }
</style>
