import { createRouter, createWebHashHistory } from "vue-router";
import responseAlert from "../services/sweet_alert";

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    component: () => import("../views/LoginPage.vue"),
  },
  {
    path: "/categories_page",
    name: "CategoriesPage",
    component: () => import("../views/CategoriesPage.vue"),
  },
  {
    path: "/branches_page",
    name: "BranchesPage",
    component: () => import("../views/BranchesPage.vue"),
  },
  {
    path: "/frenchises_page",
    name: "FrenchisesPage",
    component: () => import("../views/FrenchisesPage.vue"),
  },
  {
    path: "/products_page",
    name: "ProductsPage",
    component: () => import("../views/ProductsPage.vue"),
  },
  {
    path: "/latest_products_page",
    name: "LatestProductsPage",
    component: () => import("../views/LatestProductsPage.vue"),
  },
  {
    path: "/news_page",
    name: "NewsPage",
    component: () => import("../views/NewsPage.vue"),
  },
  {
    path: "/users_page",
    name: "UsersPage",
    component: () => import("../views/UsersPage.vue"),
  },
  {
    path: "/agents_targets",
    name: "AgentsTargets",
    component: () => import("../views/AgentsTargets.vue"),
  },
  {
    path: "/roles_page",
    name: "RolesPage",
    component: () => import("../views/RolesPage.vue"),
  },
  {
    path: "/roles_page/:id",
    name: "RolesPermessionsPage",
    component: () => import("../views/RolesPermessionsPage.vue"),
  },
  {
    path: "/orders_page",
    name: "OrdersPage",
    component: () => import("../views/OrdersPage.vue"),
  },
  {
    path: "/orders_page/:id",
    name: "OrderDetailsPage",
    component: () => import("../views/OrderDetailsPage.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, _, next) => {
  if (
    to?.path != "/login" &&
    !localStorage["hala_token"] &&
    !localStorage["hala_user"]
  )
    next("/login");
  else if (
    to?.path == "/login" &&
    localStorage["hala_token"] &&
    localStorage["hala_user"]
  ) {
    if (
      JSON.parse(localStorage["hala_user"])
        ?.user_roles?.map((i) => i?.role?.role)
        ?.includes("warehouse_manager")
    )
      router.push({ path: "/orders_page", query: { type: "new" } });
    else next("/categories_page");
  } else if (
    to?.path != "/login" &&
    localStorage["hala_token"] &&
    localStorage["hala_user"]
  ) {
    if (
      JSON.parse(localStorage["hala_user"])
        ?.user_roles?.map((i) => i?.role?.role)
        ?.includes("super_admin")
    ) {
      next();
    } else if (
      JSON.parse(localStorage["hala_user"])
        ?.user_roles?.map((i) => i?.role?.role)
        ?.includes("sale_user")
    ) {
      if (to?.path != "/orders_page" && to?.path != "/users_page") next();
      else {
        responseAlert("error", "صلاحياتك غير كافية لهذا الإجراء");
        next("/categories_page");
      }
    } else {
      if (to?.path == "/orders_page") next();
      else {
        responseAlert("error", "صلاحياتك غير كافية لهذا الإجراء");
        next("/orders_page");
      }
    }
  } else if (
    to?.path == "/login" &&
    !localStorage["hala_token"] &&
    !localStorage["hala_user"]
  ) {
    next();
  } else {
    console.log("unexpected condition in route guards");
    responseAlert("error", "حدث حطأ غير متوقع");
  }
});

export default router;
